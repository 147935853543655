:root {
    --main-bg-color: #fff3e8;
    --light-orange: rgba(253, 182, 125, 0.8);
    --dark-orange: #d87c2c;
    --dark-red: #f75876;
    --light-red: rgba(247, 88, 118, 0.2);
    --dark-green: #2fd98b;
    --light-green: rgba(47, 217, 139, 0.2);
    --dark-yellow: #fec73f;
    --light-yellow: rgba(254, 199, 63, 0.3);
    --light-purple: rgba(133, 96, 236, 0.2);
    --dark-purple: #8560ec;
    --med-orange: #fc7200;
    --light-blue: #edf5ff;
    --dark-blue: #418eeb;
}

body {
    background-color: rgba(0, 0, 0, 0.2);
}
button:hover {
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
button {
    transition: ease-in 0.15s all;
    border-radius: 0.3vw;
    outline: none;
    border: solid rgba(0, 0, 0, 0.6) 0.1vw;
}

.display-flex-row-center {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}
.display-flex-row-sb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.display-flex-col-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.padding1 {
    padding: 1vw;
}

.page-title {
    width: 100%;
    text-align: center;
    color: black;
    font-size: 1.3vw;
    font-weight: 600;
    padding: 1vw;
    margin-top: 2vw;
}

.azure-sign-in-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 1vw;
}
.azure-sign-in-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--dark-red);
    font-size: 0.8vw;
    font-weight: 600;
}
.azure-sign-in-btn {
    font-size: 0.8vw;
}

.application-list-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    border: solid rgba(0, 0, 0, 0.3) 0.1vw;
    border-radius: 0.3vw;
    width: 48%;
    padding: 1vw;
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.3);
}
.application-list-title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 0.8vw;
}
.application-view-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5vw;
    margin-bottom: 0.5vw;
    border: solid rgba(0, 0, 0, 0.3) 0.1vw;
    border-radius: 0.3vw;
    background-color: rgba(0, 0, 0, 0.3);
}
.application-view-button {
    width: 25%;
    font-size: 0.8vw;
    font-weight: 600;
}
.contact-applicant-popup {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0.5vw;
    justify-content: center;
    border-radius: 0.3vw;
    width: 100%;
}
.contact-applicant-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.25vw;
    font-size: 0.75vw;
    background-color: var(--light-blue);
}
.contact-applicant-a {
    padding-left: 0.25vw;
}
.contact-applicant-bdi {
    font-weight: 600;
}
.hire-button {
    font-size: 0.7vw;
    font-weight: 600;
    width: 50%;
    align-self: center;
    margin-top: 0.3vw;
}

.reason-closed-input {
    font-size: 0.65vw;
    padding-left: 0.2vw;
    border-radius: 0.3vw;
}

.application-view-title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1vw;
    padding-bottom: 0.5vw;
}
.application-list-table {
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    border: solid rgba(0, 0, 0, 0.3) 0.1vw;
    border-radius: 0.3vw;
    font-size: 0.75vw;
    background-color: white;
}
.application-list-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.25vw;
    border-bottom: solid rgba(0, 0, 0, 0.5) 0.1vw;
}
.application-list-data-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.25vw;
}
.application-list-data-row:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.application-list-data-row:nth-child(2n + 1):hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.application-list-data-row:nth-child(2n + 1) {
    background-color: var(--light-blue);
}

.application-list-data-row-x {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.25vw;
}

.application-list-data-row-x:nth-child(2n + 1) {
    background-color: var(--light-red);
}

.application-option-btn-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    align-self: center;
    padding: 2vw;
}

.application-option-btn {
    width: 33%;
    padding: 0.5vw;
    font-size: 0.8vw;
    font-weight: 600;
}

.application-parts-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: ease-in all 1s;
    border: solid rgba(0, 0, 0, 0.4) 0.1vw;
    border-radius: 0.3vw;
    padding: 1vw;
    width: 85%;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.3);
    margin-bottom: 5vw;
}

.application-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: white;
    border-radius: 0.3vw;
    padding: 1vw;
}
.application-form-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65%;
    align-self: center;
    font-size: 0.8vw;
    border: solid rgba(0, 0, 0, 0.4) 0.1vw;
    border-radius: 0.3vw;
    padding: 0.5vw;
    margin: 0.5vw 0vw;
}

.application-form-bdi {
    width: 25%;
}
.application-form-input {
    outline: none;
    padding-left: 0.25vw;
    border: none;
    width: 75%;
}
.application-form-input-a {
    outline: none;
    padding-left: 0.25vw;
    border: none;
    width: 75%;
    transition: ease all 0.15s;
}
.application-form-input-a:hover {
    filter: opacity(0.5);
    cursor: pointer;
}
.application-form-button {
    margin: 1vw 0vw;
    width: 33%;
    align-self: center;
    font-weight: 600;
    font-size: 0.8vw;
    padding: 0.5vw;
}

.hide-password-icon {
    width: 5%;
    transition: ease-in all 0.15s;
}

.hide-password-icon:hover {
    filter: opacity(0.5);
    cursor: pointer;
}

.error-message {
    color: var(--dark-red);
    font-size: 0.7vw;
    font-weight: 600;
    text-align: center;
}

.main-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.5vw;
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.5);
}

.application-started {
    color: white;
    font-size: 0.8vw;
}

.main-header-logo {
    width: 17%;
}
.hiring-image-div-frame {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-self: center;
    padding: 1vw;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.3vw;
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.5);
}

.hiring-image-div {
    display: flex;
    flex-direction: column;
    width: 85%;
    align-self: center;
    padding: 1vw;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.3vw;
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.5);
}

.hiring-image {
    width: 80%;
    height: 80%;
    align-self: center;
    box-shadow: 0em 0em 1em rgba(255, 255, 255, 0.8);
}

.apply-now-button {
    width: 33%;
    margin-top: 1vw;
    align-self: center;
    font-size: 1.8vw;
    text-align: center;
    align-self: center;
    width: 100%;
    color: var(--med-orange);
    transition: ease-in all 0.15s;
}
.apply-now-button:hover {
    color: var(--dark-yellow);
    cursor: pointer;
}

.home-header {
    text-align: center;
    font-size: 2vw;
    color: var(--med-orange);
    padding-bottom: 1vw;
}
.progress-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.5vw;
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.5);
}
.progress-toggle-img {
    width: 0.75%;

    align-self: center;
    transform: rotate(180deg);
}
.progress-a {
    font-size: 0.7vw;
    align-self: center;
    color: var(--dark-yellow);
    font-weight: 600;
    width: 4.25%;
    text-align: center;
    transition: ease-in all 0.15s;
    padding: 0.25vw 0vw;
}

.progress-a-selected {
    font-size: 0.7vw;
    align-self: center;
    color: var(--dark-orange);
    font-weight: 600;
    width: 4.25%;
    text-align: center;
    transition: ease-in all 0.15s;
    padding: 0.25vw 0vw;
    text-decoration-line: blink;
}

.progress-a-inactive {
    font-size: 0.7vw;
    align-self: center;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
    width: 4.25%;
    text-align: center;
    transition: ease-in all 0.15s;
    padding: 0.25vw 0vw;
    text-decoration-line: blink;
}

.progress-a:hover {
    cursor: pointer;
    color: var(--dark-orange);

    text-decoration-line: blink;
}
.progress-a-selected:hover {
    cursor: none;
    color: var(--dark-orange);
}

.employment-application-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 0.3vw;
    padding: 0.5vw;
}

.add-employment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    background-color: white;
    border-radius: 0.3vw;
}
.show-employment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 79%;
    background-color: white;
    border-radius: 0.3vw;
}

.employment-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.entries-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.6vw;
    justify-content: start;
    height: 100%;
    border: solid rgba(0, 0, 0, 0.4) 0.1vw;
    border-radius: 0.3vw;
    padding: 0.25vw;
    margin-bottom: 1vw;
}
td,
th {
    padding: 0.25vw;
    text-align: center;
    align-self: center;
}

.entries-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.3);
}

.entries-row-data {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    transition: all ease-in 0.15s;
}

.entries-row-data:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.employment-application-form-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-self: center;
    font-size: 0.65vw;
    border: solid rgba(0, 0, 0, 0.4) 0.1vw;
    border-radius: 0.3vw;
    padding: 0.25vw;
    margin: 0.25vw 0vw;
}

.edit-entry-input {
    width: 100%;
}

.employment-gaps-title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 0.9vw;
    color: white;
}

.general-attach-img {
    width: 75%;
    padding: 1vw;
    align-self: center;
}

.pdf-viewer-style {
    width: 100%;
    height: 95vh;
    padding: 0vw;
    border-radius: 0.3vw;
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.5);
}

.sign-here-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1vw;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0.3vw;
    border: solid 0.15vw var(--dark-red);
}

.sign-here-a {
    color: var(--dark-red);
    font-size: 0.8vw;
    text-align: center;
    width: 100%;
    align-self: center;
    font-weight: 600;
    transition: ease-in all 0.15s;
}

.sign-here-a:hover {
    color: var(--dark-purple);
    cursor: pointer;
}

.signature-box {
    padding: 1vw;
    border-radius: 0.3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 0.15vw rgba(0, 0, 0, 0.3);
}
.signature-signed-img {
}
.signature-signed-img:hover {
    filter: invert(0.5);
}
.signature-signed-date {
    font-size: 0.75vw;
    text-align: center;
    padding: 1vw;
    font-style: italic;
}

.driver-check-header {
    display: flex;
    padding: 0.25vw;
    width: 100%;
    height: 12vh;
}
.driver-check-img {
    width: 100%;
    height: 100%;
}

.driver-check-personal-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    align-self: center;
}
